<template>
    <main>
      <h1 class="headline blue--text text-center mt-4 mb-3">Käyttäjien seuranta</h1>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="2">
          <v-text-field v-model="startDate" placeholder="01.12.23" label="alku"/>
        </v-col>
        <v-col cols="2">
          <v-text-field v-model="endDate" placeholder="31.12.23" label="loppu"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field v-model="msisdn" placeholder="0401234567" label="asiakkaan puh. numero"></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn
              class="v-btn ma-3 green"
              @click="getResource"
              small
              :disabled="resourceLoading"
          >
            HAE
          </v-btn>
        </v-col>
      </v-row>
      <v-card v-if="!resourceLoading">
        <v-card-text v-if="resource.length > 0">
          <v-simple-table>
            <thead>
            <tr>
              <th>
                Aika
              </th>
              <th>
                Lähde
              </th>
              <th>
                Kohde
              </th>
              <th>
                Data
              </th>
            </tr>
            </thead>
            <tbody>
              <template v-for="(tr,key) in resource">
                <tr :key="'tr'+key" :class="{'green': tr.type == 'smsgw message in','light-green': tr.type == 'smsgw message out', 'blue': tr.type == 'netchat message in','purple': tr.type == 'netchat message out', 'brown': tr.type == 'smsgw message bulk'}">
                  <td>
                    {{ tr.event_timestamp }}
                  </td>
                  <td>
                    {{ tr.sender }}
                  </td>
                  <td>
                    {{ tr.recipient }}
                  </td>
                  <td>
                    <template v-if="tr.transaction && tr.transaction.service">
                      {{ tr.transaction.service.name }}
                    </template>
                    <template v-if="tr.content">
                      {{ tr.content }}
                    </template>
                      
                  </td>
                </tr>
              </template>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card v-else>
          <v-card-text>
            Ei hakutuloksia
          </v-card-text>
        </v-card>
      </v-card>
      <v-skeleton-loader v-else
                         class="mx-auto"
                         type="card, article, actions"
      >
      </v-skeleton-loader>
    </main>
</template>
<script>
export default {
  name: "UserTracking",
  data: function () {
    return {
      startDate: null,
      endDate: null,
      resource: [],
      resourceLoading: false,
      msisdn: null
    }
  },
  methods: {
    async getResource(){
      if(this.resourceLoading) return
      this.resourceLoading = true
      this.resource = null
      await this.axios.get('/reports/user_tracking', {
        params: {
          from: this.startDate,
          to: this.endDate,
          msisdn: this.msisdn //msisdn 0452010300
        }
      }).then((resources) => {
        this.resource = resources.data
        this.resourceLoading = false
      }).catch ((error) => {
        console.error(error);
      });
      this.resourceLoading = false
    },
  },
  mounted() {
    this.startDate = this.$day().startOf('month').format("DD.MM.YY")
    this.endDate = this.$day().format("DD.MM.YY")
    //this.getResource()
  }
}
</script>
<style scoped>

</style>